import React from "react";

export const HeadingPage = ({text}) => {
    return (
        <>
            <div className="background-gray">
                <div className="container">
                    <h1 className="px-5">
                        {text}
                    </h1>
                </div>
            </div>
        </>
    )
}

