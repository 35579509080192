import React from "react";
import {Navbar} from "../components/navbar.component";
import {Footer} from "../components/footer.component";
import {HeadingPage} from "../components/heading-page.component";
import IconCall from "../images/i-phone-forwarded.png";
import {graphql} from "gatsby";
import ImageGallery from "react-image-gallery";

/**
 *
 * @param {PrismicMenu} mainMenu
 * @param {PrismicMenu} footerMenu
 * @param {PrismicVehicle} prismicVehicle
 * @param {PrismicSocialLinks} prismicSocialLinks
 * @param {PrismicPageDataBodyVehiclesPrimary} labels
 * @param lang - currently selected language
 * @return {JSX.Element}
 * @constructor
 */

const VehicleDetailsPage = ({
                              data: {
                                mainMenu,
                                footerMenu,
                                prismicVehicle,
                                prismicSocialLinks,
                                vehicleDetailsPage,
                                sendOrderForm,
                                contacts
                              },
                              pageContext: {
                                lang
                              }
}) => {
  const labels = vehicleDetailsPage.data.body[0].primary;
  let {
    engine,
    millage,
    type,
    transmission,
    price,
    year,
    price_in_hryvnia,
    make,
    pictures,
    vin,
    model,
    drive_unit,
    engine_volume,
  } = prismicVehicle.data;
  make = make?.document?.data?.name;
  model = model?.document?.data?.name;
  return (
    <>
      <Navbar menu={mainMenu}
              social={prismicSocialLinks}
              lang={lang}
              sendOrderForm={sendOrderForm}
              contacts={contacts}/>
      <HeadingPage text={labels.title.text} />
      <section className="container padding-wrapper">
        <div className="row">
          <div className="col-12 col-md-4">
            <ImageGallery items={pictures.map(pic => {
              const original = pic.vehicle_picture.url;
              const thumbnail = pic.vehicle_picture.fixed.srcSetWebp.split(",\n")[0];
              return {
                original,
                thumbnail,
              };
            })} />
          </div>
          <div className="col-12  col-md-8">
            <div className="mb-4">
              <h4 className="mb-3">{year} {make} {model}</h4>
              <div>
                <div className="text-dark h3">$ {price}</div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-4">
                <ul className="text-secondary font-weight-500 line-height-30">
                  <li>{labels.label_vin}</li>
                  <li>{labels.label_type}</li>
                  <li>{labels.label_mileage} ({labels.label_miles.toUpperCase()})</li>
                  <li>{labels.label_fuel}</li>
                  <li>{labels.label_transmission}</li>
                  <li>{labels.label_drive}</li>
                  <li>{labels.label_engine_volume}</li>
                </ul>
              </div>
              <div className="col-8">
                <ul className="font-weight-500 line-height-30">
                  <li>{vin}</li>
                  <li>{type}</li>
                  <li>{millage}</li>
                  <li>{engine}</li>
                  <li>{transmission}</li>
                  <li>{drive_unit}</li>
                  <li>{engine_volume}</li>
                </ul>
              </div>
            </div>
            <a href="#form-popup" className="btn btn-mb btn-primary">
              <img className="icon-call" alt="i" src={IconCall} />
              {labels.label_call_manager}
            </a>
          </div>
        </div>
      </section>
      <Footer menu={footerMenu} socials={prismicSocialLinks} contacts={contacts}/>
    </>
  );
};

export default VehicleDetailsPage;

export const query = graphql`
  query VehicleQuery($id: String!, $lang: String!) {
    prismicVehicle(uid: {eq: $id}) {
      data {
        discount
        engine
        make {
          document {
            ... on PrismicMake {
              data {
                name
              }
            }
          }
        }
        model {
          document {
            ... on PrismicModel {
              data {
                name
              }
            }
          }
        }
        millage
        pictures {
          is_featured
          vehicle_picture {
            alt
            gatsbyImageData
            url
            fixed {
              srcSetWebp
            }
          }
        }
        price
        transmission
        type
        vin
        year
        drive_unit
        engine_volume
      }
    }
    mainMenu: prismicMenu(uid: {eq: "main-menu"}, lang: { eq: $lang }) {
      ...menu
    }
    footerMenu: prismicMenu(uid: {eq: "footer-menu"}, lang: { eq: $lang }) {
      ...menu
    }
    sendOrderForm: prismicSendOrderForm(lang: {eq: $lang}){
      ...sendOrderForm
    }
    contacts: prismicContacts{
      ...contacts
    }
    prismicSocialLinks(type: {eq: "social_links"}) {
      ...socials
    }
    vehicleDetailsPage: prismicPage(uid: {eq:"vehicle-details"}, lang: { eq: $lang }){
      data {
        body {
          ... on PrismicPageDataBodyVehicles {
            id
            primary {
              label_call_manager
              label_drive
              label_engine_volume
              label_fuel
              label_mileage
              label_miles
              label_transmission
              label_type
              label_vin
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`;
